import { useContext, useState } from 'react';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { Paths } from '../../Routes';
import AppPropsContext from '../../context/appPropsContext';
import { GoogleAnalyticsAction, GoogleAnalyticsCategory, useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import { useLanguage } from '../../hooks/useLanguage';
import StyledButton from '../Buttons/StyledButton';
import { StyledMainCardTitle, StyledProfileCard } from '../Cards/StyledCard';
import { createSelectComboboxOption, ISelectComboboxOption, SelectCombobox } from '../Combobox/Combobox';
import { CenterDiv, RightDiv } from '../Div/StyledDiv';
const EnrollCodeBox = () => {
    const navigate = useNavigate();

    const { t } = useLanguage();
    const themeContext = useContext(ThemeContext);
    const { apstate } = useContext(AppPropsContext);

    const { sendGoogleAnalyticsEvent } = useGoogleAnalytics();

    //const screenSize = useScreenResolution();

    const [anmeldKatSelected, setAnmeldKatSelected] = useState<ISelectComboboxOption>();

    // const getMarginRightLeft = () => {
    //     if (screenSize.width > 1700) {
    //         return '50px';
    //     } else if (screenSize.width > 1300) {
    //         return '20px';
    //     } else {
    //         return '10px';
    //     }
    // };

    const handleSetAnmeldKat = (e: any) => {
        // setAnmeldKatSelected(e.target.value.toString());
        const option: ISelectComboboxOption = createSelectComboboxOption(e.value.toString());
        setAnmeldKatSelected(option);
    };

    const getAnmeldKatOptions = () => {
        const anmeldKatOptions: ISelectComboboxOption[] = [];

        if (apstate.anmeldeContexts && apstate.anmeldeContexts.length > 0) {
            // return apstate.anmeldeContexts.map((anmeldeContext) => (
            //     <option key={anmeldeContext.code} value={anmeldeContext.code}>
            //         {anmeldeContext.name}
            //     </option>
            // ));
            apstate.anmeldeContexts.forEach((anmeldeContext) => {
                const optionSelect: ISelectComboboxOption = { value: anmeldeContext.code, label: anmeldeContext.name };
                anmeldKatOptions.push(optionSelect);
            });

            return anmeldKatOptions;
        } else {
            return null;
        }
    };

    const handleTerminAnfordernClick = () => {
        sendGoogleAnalyticsEvent({
            category: GoogleAnalyticsCategory.HOMESCREEN,
            action: GoogleAnalyticsAction.CLICK,
            label: 'Standort ' + anmeldKatSelected?.value + ' ausgewählt',
        });
        navigate(Paths.NEUER_ACCOUNT_WL + '?reason=' + anmeldKatSelected?.value + '&type=NewAccount');
    };

    return (
        <Row style={{ height: '100%' }}>
            <Col style={{ display: 'flex' }}>
                <StyledProfileCard>
                    <Card.Body>
                        <StyledMainCardTitle>{t('LOGIN', 'Ihre Terminplanung ohne Account')}</StyledMainCardTitle>
                        <Container style={{ marginTop: '35px' }}>
                            <CenterDiv>
                                <FaRegCalendarAlt size={68} color={themeContext.icon.passive.color} />
                            </CenterDiv>
                            <CenterDiv style={{ marginTop: '20px', marginBottom: '20px' }}>
                                {/* <Form.Control
                                    as="select"
                                    name="reason"
                                    defaultValue=""
                                    value={anmeldKatSelected}
                                    onChange={(e) => handleSetAnmeldKat(e)}
                                >
                                    <option disabled key="anmeldKatDefault" hidden value="">
                                        {t('LOGIN', 'Standort wählen')}
                                    </option>
                                    {getAnmeldKatOptions()}
                                </Form.Control> */}
                                <SelectCombobox
                                    menuPortalTarget={document.querySelector('body')}
                                    options={getAnmeldKatOptions()}
                                    placeholder={t('LOGIN', 'Standort wählen')}
                                    // value={anmeldKatSelected}
                                    isSearchable={true}
                                    onChange={(e) => handleSetAnmeldKat(e)}
                                />
                            </CenterDiv>
                            <RightDiv>
                                <StyledButton
                                    disabled={!anmeldKatSelected}
                                    variant="primary"
                                    onClick={() => handleTerminAnfordernClick()}
                                >
                                    {t('LOGIN', 'Neuen Termin anfordern')}
                                </StyledButton>
                            </RightDiv>
                        </Container>
                    </Card.Body>
                </StyledProfileCard>
            </Col>
        </Row>
    );
    /* ALTE VARIANTE 
    return (
        <Row style={{ height: '100%' }}>
            <Col style={{ display: 'flex' }}>
                <StyledProfileCard>
                    <Card.Body>
                        <StyledMainCardTitle>Sie haben einen Termincode?</StyledMainCardTitle>
                        <Row style={{ marginTop: '75px', display: 'flex', textAlign: 'center' }}>
                            <Col style={{ marginTop: '0px', textAlign: 'left' }}>
                                <EnrollCodeInput numberOfInputFields={12} />
                            </Col>
                            <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <FaRegCalendarAlt size={68} color={themeContext.icon.passive.color} />
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <div style={{ float: 'right' }}>
                            <StyledButton variant="primary" onClick={() => navigate(Paths.TERMINIERUNG_WL)}>
                                Neuen Termin anfordern
                            </StyledButton>
                        </div>
                    </Card.Footer>
                </StyledProfileCard>
            </Col>
        </Row>
    );
    */
};

export default EnrollCodeBox;
