import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ThemeContext } from 'styled-components';
import { CategoryBadgeSelected } from '../../components/Badges/StyledBadge';
import { AnmeldBaseDiv, AnmeldBaseHeader, AnmeldBaseHeaderLogin, CenterDiv } from '../../components/Div/StyledDiv';
import LoadingSpinnerWithText from '../../components/Loading/LoadingSpinnerWithText';
import PhysicianSelectionCardWithAppointments from '../../components/PhysicianSelectionCard/PhysicianSelectionCardWithAppointments';
import PhysicianSelectionCardWithDateSwitcher from '../../components/PhysicianSelectionCard/PhysicianSelectionCardWithDateSwitcher';
import { ParagraphFontSize } from '../../components/StyledParagraph/StyledParagraph';
import { StyledMainHeader, StyledSubHeader } from '../../components/StyledText/StyledHeader';
import AppPropsContext from '../../context/appPropsContext';
import LoginContext, { IDepartmentReasons } from '../../context/loginContext';
import TerminierungContext, { ISelectedPhysician } from '../../context/terminierungContext';
import { useAppProperties } from '../../hooks/useAppProperties';
import { useAppointmentSelection } from '../../hooks/useAppointmentSelection';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import { useLanguage } from '../../hooks/useLanguage';
import useScreenResolution from '../../hooks/useScreenResolution';
import { IPhysiciansFor, getPhysiciansFor, unblockAppointment } from '../../services/RestServices';
import { INeuerAccountWL } from '../NeuerAccount/NeuerAccountWL';

interface IAppointmentCategoryExtendedPhysician {
    department: string;
    reason: IDepartmentReasons;
    handleTimeslotClick(currentActiveStep: number, hdBooking?: string): void;
    currentActiveStep: number;
    newAccountHashParam?: INeuerAccountWL;
    showSummaryBadgeHeader?: boolean;
}

const AppointmentCategoryExtendedPhysician = (props: IAppointmentCategoryExtendedPhysician) => {
    const themeContext = useContext(ThemeContext);
    const { tmstate, tmdispatch } = useContext(TerminierungContext);
    const { state } = useContext(LoginContext);
    const { apstate } = useContext(AppPropsContext);
    const [allPhysicians, setAllPhysicians] = useState<ISelectedPhysician[]>();
    const { getAllMakroIdsFromFilteredReasons } = useAppointmentSelection();
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [makroId, setMakroId] = useState<string>('');
    const [hdTimeslots, setHdTimeslots] = useState<string>('');
    const [initalPhysicianIds, setInitialPhysicianIds] = useState<string[]>();
    const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);
    const { getCountDaysToVisualize } = useAppProperties();
    const { sendGoogleAnalyticsPageView } = useGoogleAnalytics();

    const { t } = useLanguage();

    const screenSize = useScreenResolution();

    const { getSelectedResource } = useAppointmentSelection();

    const getExtension = (app: any, url: string) => {
        for (const e of app.extension) {
            if (e.url == url) {
                return e.valueString;
            }
        }
        return null;
    };

    const getExtensionJSON = (app: any, url: string) => {
        const sjson = getExtension(app, url);
        if (sjson) {
            return JSON.parse(sjson);
        }
        return null;
    };
    const simulateBehFilterFromLocation = (app: any) => {
        let res = '';
        for (const p of app.participant) {
            const ref = p.actor.reference;
            if (ref.startsWith('Location/')) {
                if (res.length > 0) {
                    res += ',';
                }
                res += ref.substring('Location/'.length);
            } else if (ref.startsWith('Practitioner/')) {
                if (res.length > 0) {
                    res += ',';
                }
                res += ref.substring('Practitioner/'.length);
            }
        }
        return res;
    };
    useEffect(() => {
        sendGoogleAnalyticsPageView({
            page: '/AppointmentCategoryExtendedPhysician',
            title: 'Behandler und Termin wählen',
        });

        const getPhysicians = async () => {
            let physiciansForData: IPhysiciansFor;
            if (tmstate.cancelNewAppointmentData?.data) {
                const app = tmstate.cancelNewAppointmentData?.data;
                const _makroId = app.serviceType[0].coding[0].id;
                const behFilter = simulateBehFilterFromLocation(app);
                setMakroId(_makroId);
                physiciansForData = {
                    makroId: _makroId,
                    selectedReason: getExtensionJSON(app, 'http://www.principa.siegele-software.com/selectedReason')
                        ?.reasonId,
                    selectedInsurance: getExtension(app, 'http://www.principa.siegele-software.com/selectedInsurance'),
                    selectedDepartment: getExtension(
                        app,
                        'http://www.principa.siegele-software.com/selectedDepartment',
                    ),
                    selectedAptType: getExtension(app, 'http://www.principa.siegele-software.com/selectedAptType'),
                    countDaysToVisualize: getCountDaysToVisualize(),
                    extendedPhysicians: apstate.extendedPhysicians,
                    // behFilter: getExtension(app, 'http://www.principa.siegele-software.com/behFilter'),
                    behFilter: behFilter,
                };
            } else {
                const _makroId = getAllMakroIdsFromFilteredReasons();
                setMakroId(_makroId);
                physiciansForData = {
                    makroId: _makroId,
                    selectedReason: tmstate.selectedReason.reasonId,
                    selectedInsurance: tmstate.selectedInsurance,
                    selectedDepartment: tmstate.selectedDepartment.name,
                    selectedAptType: tmstate.selectedAppointmentType,
                    countDaysToVisualize: getCountDaysToVisualize(),
                    extendedPhysicians: apstate.extendedPhysicians,
                };
                if (props.newAccountHashParam?.behFilter) {
                    physiciansForData.behFilter = props.newAccountHashParam?.behFilter;
                }
            }

            setShowLoadingSpinner(true);
            const physiciansData = await getPhysiciansFor(physiciansForData);
            setShowLoadingSpinner(false);
            if (physiciansData?.physicians) {
                setAllPhysicians(physiciansData.physicians);
                setHdTimeslots(physiciansData.hdTimeslots);
                const allPhysicianIds: string[] = [];
                physiciansData.physicians.forEach((e: ISelectedPhysician) => {
                    if (e.id) {
                        allPhysicianIds?.push(e.id.toString());
                    }
                });
                setInitialPhysicianIds(allPhysicianIds);
            }
        };

        tmdispatch({
            type: 'RESETTIMESLOT',
        });

        /* unblock appointment */
        const resource_1 = getSelectedResource(1);
        if (resource_1) {
            unblockAppointment(resource_1);
        }

        /* get physicians data*/

        getPhysicians();
    }, []);

    const getNewPhyscianData = async (myDate: moment.Moment) => {
        const physiciansForData: IPhysiciansFor = {
            makroId: makroId,
            selectedReason: tmstate.selectedReason.reasonId,
            selectedInsurance: tmstate.selectedInsurance,
            selectedDepartment: tmstate.selectedDepartment.name,
            selectedAptType: tmstate.selectedAppointmentType,
            countDaysToVisualize: getCountDaysToVisualize(),
            extendedPhysicians: apstate.extendedPhysicians,
            startDate: myDate.format('DD.MM.yyyy'),
            currentPhys: initalPhysicianIds?.join(),
        };
        if (props.newAccountHashParam?.behFilter) {
            physiciansForData.behFilter = props.newAccountHashParam?.behFilter;
        }

        setShowLoadingSpinner(true);
        const physiciansData = await getPhysiciansFor(physiciansForData);
        setShowLoadingSpinner(false);
        if (physiciansData?.physicians) {
            setAllPhysicians(physiciansData.physicians);
        }
    };

    const handlePreviousDate = () => {
        const dateToday = new Date();

        const offset = apstate.countDaysToVisualize ? getCountDaysToVisualize() : 7;

        const myDate = moment(startDate).add(offset * -1, 'days');

        if (moment(myDate).isBefore(dateToday, 'day')) {
            /* no switch to past dates */
            return;
        }
        setStartDate(myDate.toDate());

        getNewPhyscianData(myDate);
    };
    const handleNextDate = async () => {
        const offset = apstate.countDaysToVisualize ? getCountDaysToVisualize() : 7;

        const myDate = moment(startDate).add(offset, 'days');
        setStartDate(myDate.toDate());

        getNewPhyscianData(myDate);
    };

    const handleStartDate = async (firstPossibleDate: string) => {
        const myDate = moment(firstPossibleDate, 'DD.MM.yyyy', true);
        setStartDate(myDate.toDate());

        getNewPhyscianData(myDate);
    };

    const DateHeader = () => {
        const allCards: any[] = [];

        allCards.push(
            <div key="TimeStepper">
                <PhysicianSelectionCardWithDateSwitcher
                    startDate={startDate}
                    handlePreviousDate={handlePreviousDate}
                    handleNextDate={handleNextDate}
                    countDaysToVisualize={apstate.countDaysToVisualize ? getCountDaysToVisualize() : 7}
                />
            </div>,
        );
        return allCards;
    };
    const allPhysicianCards = () => {
        const allCards: any[] = [];

        allPhysicians?.forEach((e: ISelectedPhysician) => {
            let descrList: string[] = [];
            if (e.lines && typeof e.lines === 'string') {
                descrList.push(e.lines);
            } else if (e.lines) {
                descrList = e.lines;
            }
            allCards.push(
                <div key={e.id}>
                    <PhysicianSelectionCardWithAppointments
                        name={e.name}
                        showPicture={true}
                        pict={e.pict ? e.pict : ''}
                        description={descrList}
                        startDate={startDate}
                        maxTimeSlots={6}
                        countDaysToVisualize={apstate.countDaysToVisualize ? getCountDaysToVisualize() : 7}
                        appointments={e.appointments}
                        handleTimelotClick={props.handleTimeslotClick}
                        currentActiveStep={props.currentActiveStep}
                        blockMessage={e.blockMessage}
                        hdBooking={e.hdBooking}
                        showLoadingSpinner={showLoadingSpinner}
                        firstPossibleDate={e.firstPossibleDate}
                        handleStartDate={handleStartDate}
                    />
                </div>,
            );
        });

        return allCards;
    };

    const MyAnmeldBaseHeader = (props) => {
        if (state.isLoggedIn) {
            return <AnmeldBaseHeaderLogin>{props.children}</AnmeldBaseHeaderLogin>;
        } else {
            return <AnmeldBaseHeader>{props.children}</AnmeldBaseHeader>;
        }
    };
    const getTimeslotHeader = () => {
        if (hdTimeslots) {
            return <CenterDiv id="hdTimeSlots" dangerouslySetInnerHTML={{ __html: hdTimeslots }} />;
        }
        return null;
    };

    return (
        <>
            {showLoadingSpinner && (
                <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
                    <LoadingSpinnerWithText loadingText="" />
                </div>
            )}
            <AnmeldBaseDiv id="AppCatExtPhysAnmeldBaseDiv">
                {getTimeslotHeader()}
                <MyAnmeldBaseHeader>
                    {screenSize.height && screenSize.height > 600 && (
                        <Row xs={1} lg={props.showSummaryBadgeHeader ? 3 : 1}>
                            <Col>
                                <StyledMainHeader>{t('ANMELD', 'Behandler und Termin wählen')}</StyledMainHeader>
                            </Col>
                            {props.showSummaryBadgeHeader && (
                                <>
                                    <Col>
                                        <StyledSubHeader>
                                            {t('ANMELD', 'Fachrichtung')}
                                            <CategoryBadgeSelected
                                                backgroundColor={themeContext.mainColor}
                                                color="white"
                                                marginLeft="20px"
                                                fontSize={ParagraphFontSize.Medium}
                                            >
                                                {props.department ? props.department : ''}
                                            </CategoryBadgeSelected>
                                        </StyledSubHeader>
                                    </Col>
                                    <Col>
                                        <StyledSubHeader>
                                            {t('ANMELD', 'Besuchsgrund')}
                                            <CategoryBadgeSelected
                                                backgroundColor={themeContext.mainColor}
                                                color="white"
                                                marginLeft="20px"
                                                fontSize={ParagraphFontSize.Medium}
                                            >
                                                {props.reason?.reasonName ? props.reason.reasonName : ''}
                                            </CategoryBadgeSelected>
                                        </StyledSubHeader>
                                    </Col>
                                </>
                            )}
                        </Row>
                    )}
                    {DateHeader()}
                </MyAnmeldBaseHeader>
                {allPhysicianCards()}
            </AnmeldBaseDiv>
        </>
    );
};

export default AppointmentCategoryExtendedPhysician;
