import { default as Select, StylesConfig } from 'react-select';
import { useLanguage } from '../../hooks/useLanguage';

export interface ISelectComboboxOption {
    value: string;
    label: string;
}

export interface ISelectComboboxStyle {
    isInvalid?: boolean;
}

export const createSelectComboboxOption = (option: string): ISelectComboboxOption => {
    return {
        value: option,
        label: option,
    };
};

export const createSelectComboboxOptions = (options: string[]): ISelectComboboxOption[] => {
    return options.map((option) => {
        return {
            value: option,
            label: option,
        };
    });
};

export const getReactSelectComboBoxDefaultStyles = (props: ISelectComboboxStyle) => {
    const getBorderColor = (menuIsOpen: boolean, isInvalid: boolean | undefined) => {
        if (isInvalid) {
            return '#dc3545';
        } else if (menuIsOpen) {
            return '#80bdff';
        } else {
            return '#ced4da';
        }
    };

    const getBackgroundImage = (isInvalid: boolean | undefined) => {
        if (isInvalid) {
            //return 'url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 24 24%22 fill=%22none%22 stroke=%22%23dc3545%22 stroke-width=%222%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22%3E%3Ccircle cx=%2212%22 cy=%2212%22 r=%2210%22/%3E%3Cline x1=%2212%22 y1=%2216%22 x2=%2212%22 y2=%2212%22/%3E%3Cline x1=%2212%22 y1=%2218%22 x2=%2212%22 y2=%2218%22/%3E%3C/svg%3E")';
            return "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e\")";
        }
        return 'none';
    };

    const colourStyles: StylesConfig = {
        control: (styles, { menuIsOpen, isFocused, isDisabled }) => ({
            ...styles,
            backgroundColor: 'white',
            boxShadow: menuIsOpen ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : undefined,
            borderColor: getBorderColor(menuIsOpen, props.isInvalid),
            backgroundImage: getBackgroundImage(props.isInvalid),
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right calc(.375em + .1875rem) center',
            backgroundSize: 'calc(.75em + .375rem) calc(.75em + .375rem)',
            fontWeight: 400,
            lineHeight: '1.5',
            fontSize: '1rem',
            textAlign: 'left',
            paddingLeft: '0.375rem',
            '&:hover': getBorderColor(menuIsOpen, props.isInvalid),
        }),
        placeholder: (styles, {}) => {
            return {
                ...styles,
                color: '#495057',
            };
        },
        dropdownIndicator: (styles, {}) => {
            return {
                ...styles,
                color: '#60666C',
                width: '1.97rem',
            };
        },
        indicatorsContainer: (styles, {}) => {
            const showIndicator = () => {
                if (props.isInvalid) {
                    return 'none';
                }
                return 'flex';
            };

            return {
                ...styles,
                display: showIndicator(),
            };
        },
        option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                /* CHANGE FOR CUSTOM STYLES IN OPENED COMBOBOX
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? 'rgb(25, 103, 210)'
                    : isFocused
                    ? 'rgb(25, 103, 210)'
                    : undefined,
                color: isDisabled ? '#495057' : isSelected ? 'white' : isFocused ? 'white' : '#495057',*/
                fontWeight: 400,
                lineHeight: '1.5',
                fontSize: '1rem',
            };
        },
    };

    return colourStyles;
};

type ISelectComboboxProps = React.ComponentProps<typeof Select> & {
    isInvalid?: boolean;
};

export const SelectCombobox = (props: ISelectComboboxProps) => {
    const { t } = useLanguage();

    let style = getReactSelectComboBoxDefaultStyles({ isInvalid: props.isInvalid });
    if (props.styles) {
        style = props.styles;
    }
    return <Select {...props} noOptionsMessage={() => t('COMBOBOX', 'Keine Treffer gefunden')} styles={style} />;
};
