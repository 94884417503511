import produce from 'immer';
import { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../Routes';
import { INeuerAccountWL } from '../../container/NeuerAccount/NeuerAccountWL';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import CheckInContext from '../../context/checkInContext';
import LoginContext from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import { useAppointmentSelection } from '../../hooks/useAppointmentSelection';
import { GoogleAnalyticsAction, GoogleAnalyticsCategory, useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import { useLanguage } from '../../hooks/useLanguage';
import { usePersonalData } from '../../hooks/usePersonalData';
import useScreenResolution from '../../hooks/useScreenResolution';
import { IEnrollData, enroll } from '../../services/RestServices';
import { bookAppointment } from '../../services/fhir/FHIRAppointment';
import { showAlertMessage } from '../../utils/alertHandling';
import { APPOINTMENT_POSTPONE_REASON } from '../../utils/appointmentUtils';
import { calcButtonPaddingSize, lottieCheck } from '../../utils/lottieUtils';
import StyledButton from '../Buttons/StyledButton';
import { isTimeslotDateSet } from '../Calendar/TimeSlots';
import StyledCol from '../Cols/StyledCol';
import { StickyButtonRowBottomDiv } from '../Div/StyledDiv';
import { LottieButtonLoadingSpinner } from '../Loading/Loading';
import CancelConfirmModal, { ICancelConfirmModalShow } from '../Modals/CancelConfirmModal/CancelConfirmModal';
import CodeInputModal, { ICodeInputModalShow } from '../Modals/CodeInputModal/CodeInputModal';
import { StyledModal } from '../Modals/StyledModal/StyledModal';
import { StyledRowNoMargin } from '../Rows/StyledRow';
import { StyledP } from '../SidebarItems/Avatar';
import Booking from './Booking';

interface IBookingAppointment {
    reason: string;
    handleBookingClick(response: any, currentActiveStep: number): void;
    showCancelButton: boolean;
    handleCancelClick(currentActiveStep: number): void;
    currentActiveStep: number;
    reasonGroupText?: string;
    useLoginContext: boolean;
    isPhysicianLogin?: boolean;
    newAccountHashParam?: INeuerAccountWL;
}

interface IBookingButtons {
    handleBooking(): void;
    showCancelButton: boolean;
    handleCancel(): void;
    showWithSpaceBetween: boolean;
}

interface IBookingSuccess {
    handleNext(): void;
    show: boolean;
}

const BookingAppointment = (props: IBookingAppointment) => {
    const { getEnrollData, getEnrollDataPhysPat } = usePersonalData();
    const { state } = useContext(LoginContext);
    const { checkinstate } = useContext(CheckInContext);
    const { tmstate } = useContext(TerminierungContext);
    const { alertdispatch } = useContext(AlertContext);
    const { getSelectedTimeslot, getSelectedResource, isEarlierAppointmentPossible } = useAppointmentSelection();
    const [bookingCode, setBookingCode] = useState('');
    const [showBookingButtonLoadingSpinner, setShowBookingButtonLoadingSpinner] = useState(false);
    const [bookingSuccess, setBookingSuccess] = useState<IBookingSuccess>({ show: false, handleNext: function () {} });
    const navigate = useNavigate();
    const { sendGoogleAnalyticsEvent } = useGoogleAnalytics();

    const { t } = useLanguage();

    const screenSize = useScreenResolution();

    const bookingButtonRef = useRef<any>(null);

    useEffect(() => {
        if (bookingCode && bookingCode.length > 0) {
            innerBookAppointment();
        }
    }, [bookingCode]);

    const [codeModalShow, setCodeModalShow] = useState<ICodeInputModalShow>({
        show: false,
        modalTitle: '',
        modalText: '',
    });

    const [cancelConfirmModalShow, setCancelConfirmModalShow] = useState<ICancelConfirmModalShow>({
        show: false,
    });

    const BookNowButton = (props: IBookingButtons) => {
        const handleBooking = () => {
            sendGoogleAnalyticsEvent({
                category: GoogleAnalyticsCategory.APPOINTMENT_BOOKING,
                action: GoogleAnalyticsAction.SUBMIT,
                label: 'Jetzt buchen',
            });
            setShowBookingButtonLoadingSpinner(true);
            props.handleBooking();
        };

        if (showBookingButtonLoadingSpinner) {
            let calcButtonWidth = '35px';
            const lottieSize = 24;
            calcButtonWidth = calcButtonPaddingSize(bookingButtonRef, lottieSize);

            return (
                <StyledButton
                    ref={bookingButtonRef}
                    marginLeft={props.showWithSpaceBetween ? '0px' : '100px'}
                    paddingLeft={calcButtonWidth}
                    paddingRight={calcButtonWidth}
                    variant="primary"
                >
                    <LottieButtonLoadingSpinner size={lottieSize} />
                </StyledButton>
            );
        } else {
            return (
                <StyledButton
                    ref={bookingButtonRef}
                    marginLeft={props.showWithSpaceBetween ? '0px' : '100px'}
                    onClick={() => handleBooking()}
                >
                    {t('BUTTONS', 'Jetzt buchen')}
                </StyledButton>
            );
        }
    };

    const BookingButtons = (props: IBookingButtons) => {
        const handleCancel = () => {
            sendGoogleAnalyticsEvent({
                category: GoogleAnalyticsCategory.APPOINTMENT_BOOKING,
                action: GoogleAnalyticsAction.CLICK,
                label: 'Termin buchen abbrechen',
            });
            props.handleCancel();
        };

        if (props.showWithSpaceBetween) {
            return (
                <StickyButtonRowBottomDiv marginTop="30px" paddingTop="20px">
                    <StyledRowNoMargin style={{ justifyContent: 'space-between' }}>
                        <StyledCol textAlign="left" paddingLeft="0px" paddingRight="0px">
                            {props.showCancelButton && (
                                <StyledButton variant="secondary" onClick={() => handleCancel()}>
                                    {t('BUTTONS', 'Abbrechen')}
                                </StyledButton>
                            )}
                        </StyledCol>
                        <StyledCol textAlign="right" paddingLeft="0px" paddingRight="0px">
                            <BookNowButton {...props} />
                        </StyledCol>
                    </StyledRowNoMargin>
                </StickyButtonRowBottomDiv>
            );
        } else {
            return (
                <StickyButtonRowBottomDiv marginTop="30px" paddingTop="20px">
                    <StyledRowNoMargin>
                        <StyledCol paddingLeft="0px" paddingRight="0px" textAlign="right">
                            {props.showCancelButton && (
                                <StyledButton variant="secondary" onClick={() => handleCancel()}>
                                    {t('BUTTONS', 'Abbrechen')}
                                </StyledButton>
                            )}
                            {/* <StyledButton marginLeft="100px" onClick={() => handleBooking()}>
                                Jetzt buchen
                            </StyledButton> */}
                            <BookNowButton {...props} />
                        </StyledCol>
                    </StyledRowNoMargin>
                </StickyButtonRowBottomDiv>
            );
        }
    };

    const shouldCreateAccount = () => {
        if (tmstate.personalData.username.length > 0 && tmstate.personalData.password.length > 0) {
            return true;
        }
        return false;
    };

    const getEnrollDataForBooking = async (isPhysicianLogin: boolean) => {
        let enrollData: IEnrollData;
        if (isPhysicianLogin) {
            enrollData = await getEnrollDataPhysPat();
        } else {
            enrollData = await getEnrollData();
        }

        if (bookingCode && bookingCode.length > 0) {
            enrollData.bookingCode = bookingCode;
        }

        if (tmstate.cancelNewAppointmentData.reason === APPOINTMENT_POSTPONE_REASON) {
            enrollData.makroId = tmstate.enrollData.fhir.serviceType[0].coding[0].extension[0].valueInteger.toString();
        }

        if (checkinstate.urlToCallResponse.eGK && checkinstate.urlToCallResponse.eGK.length > 0) {
            enrollData.eGK = checkinstate.urlToCallResponse.eGK;
        }
        if (checkinstate.urlToCallResponse.kvBarcode && checkinstate.urlToCallResponse.kvBarcode.length > 0) {
            enrollData.kvBarcode = checkinstate.urlToCallResponse.kvBarcode;
        }
        if (checkinstate.urlToCallResponse.kvBarcode && checkinstate.urlToCallResponse.kvBarcode.length > 0) {
            enrollData.kvBarcode = checkinstate.urlToCallResponse.kvBarcode;
        }
        if (props.newAccountHashParam?.behFilter) {
            enrollData.behFilter = props.newAccountHashParam?.behFilter;
        }

        return enrollData;
    };

    const innerBookAppointment = async () => {
        if (props.isPhysicianLogin) {
            const enrollData: IEnrollData = await getEnrollDataForBooking(true);
            setBookingCode('');

            const response = await enroll(enrollData, props.reason);

            if (!response || response.error) {
                if (response?.error.includes('MISSINGBC:')) {
                    const text = response.error.slice('MISSINGBC:'.length, response.error.length);
                    setCodeModalShow({
                        show: true,
                        modalTitle: 'Buchungscode',
                        modalText: text,
                    });
                } else {
                    showAlertMessage({
                        alertTitle: 'Terminbuchung',
                        alertTxt: response?.error ? response.error : 'Fehler in der Terminbuchung',
                        alertType: AlertType.error,
                        onHide: AlertOnHide.onlyClose,
                        alertdispatch: alertdispatch,
                    });
                }
            } else {
                props.handleBookingClick(response, props.currentActiveStep);
            }
        } else if (props.useLoginContext && !props.isPhysicianLogin) {
            let selectedResource;
            let selectedResource_2;
            let multiAppointment = false;

            const timeslot_1 = getSelectedTimeslot(1);
            const resource_1 = getSelectedResource(1);
            if (isTimeslotDateSet(timeslot_1)) {
                selectedResource = produce(resource_1, (draft: any) => {
                    draft.start = timeslot_1?.start;
                    draft.end = timeslot_1?.end;
                });
            }

            const timeslot_2 = getSelectedTimeslot(2);
            const resource_2 = getSelectedResource(2);
            if (isTimeslotDateSet(timeslot_2)) {
                selectedResource_2 = produce(resource_2, (draft: any) => {
                    draft.start = timeslot_2?.start;
                    draft.end = timeslot_2?.end;
                });
                multiAppointment = true;
            }
            const response = await bookAppointment(
                state.sessionId,
                selectedResource,
                tmstate.remark,
                tmstate.earlierAppointmentWanted,
            );
            if (!response || response.error) {
                if (response?.error.includes('MISSINGBC:')) {
                    const text = response.error.slice('MISSINGBC:'.length, response.error.length);
                    setCodeModalShow({
                        show: true,
                        modalTitle: 'Buchungscode',
                        modalText: text,
                    });
                } else {
                    showAlertMessage({
                        alertTitle: 'Terminbuchung',
                        alertTxt: response?.error ? response.error : 'Fehler in der Terminbuchung',
                        alertType: AlertType.error,
                        onHide: AlertOnHide.onlyClose,
                        alertdispatch: alertdispatch,
                    });
                }
            } else {
                setBookingSuccess({
                    show: true,
                    handleNext: function () {
                        navigate(Paths.TERMINE);
                    },
                });
            }
        } else {
            const enrollData: IEnrollData = await getEnrollDataForBooking(false);
            setBookingCode('');

            const response = await enroll(enrollData, props.reason);

            if (!response || response.error) {
                if (response?.error.includes('MISSINGBC:')) {
                    const text = response.error.slice('MISSINGBC:'.length, response.error.length);
                    setCodeModalShow({
                        show: true,
                        modalTitle: 'Buchungscode',
                        modalText: text,
                    });
                } else {
                    showAlertMessage({
                        alertTitle: 'Terminbuchung',
                        alertTxt: response?.error ? response.error : 'Fehler in der Terminbuchung',
                        alertType: AlertType.error,
                        onHide: AlertOnHide.onlyClose,
                        alertdispatch: alertdispatch,
                    });
                }
            } else {
                setBookingSuccess({
                    show: true,
                    handleNext: function () {
                        props.handleBookingClick(response, props.currentActiveStep);
                    },
                });
            }
        }
    };

    const handleBookingClick = () => {
        innerBookAppointment();
    };

    const handleCancelClick = () => {
        setCancelConfirmModalShow({ show: true });
    };

    const handleCancelClickAfterConfirm = () => {
        setCancelConfirmModalShow({ show: false });
        props.handleCancelClick(props.currentActiveStep);
    };

    const handleCloseCodeInputModal = () => {
        sendGoogleAnalyticsEvent({
            category: GoogleAnalyticsCategory.APPOINTMENT_BOOKING,
            action: GoogleAnalyticsAction.CLICK,
            label: 'Buchungscode abbrechen',
        });
        setCodeModalShow({
            show: false,
            modalTitle: '',
            modalText: '',
        });
        setShowBookingButtonLoadingSpinner(false);
    };

    const handleSubmitCodeInputModal = (code: string) => {
        sendGoogleAnalyticsEvent({
            category: GoogleAnalyticsCategory.APPOINTMENT_BOOKING,
            action: GoogleAnalyticsAction.SUBMIT,
            label: 'Buchungscode absenden',
        });
        setBookingCode(code);
        setCodeModalShow({
            show: false,
            modalTitle: '',
            modalText: '',
        });
    };

    const BookSuccessScreen = (props: IBookingSuccess) => {
        return (
            <StyledModal
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Terminbuchung erfolgreich</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Lottie options={lottieCheck} height={100} width={100} />
                    <br />
                    <StyledP style={{ fontSize: 'large' }}>
                        Ihre Terminbuchung wurde erfolgreich durchgeführt. Weitere Infos zu Ihrem Termin erhalten Sie
                        auch per E-Mail.
                    </StyledP>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <StyledCol textAlign="left"></StyledCol>
                        <StyledCol textAlign="right">
                            <StyledButton onClick={props.handleNext}>Weiter</StyledButton>
                        </StyledCol>
                    </Row>
                </Modal.Footer>
            </StyledModal>
        );
    };

    return (
        <>
            <Booking
                reasonGroupText={props.reasonGroupText}
                showEarlierAppointmentPossible={isEarlierAppointmentPossible()}
            />
            <BookingButtons
                handleBooking={handleBookingClick}
                showCancelButton={props.showCancelButton}
                handleCancel={handleCancelClick}
                // showWithSpaceBetween={screenSize.width <= 991 ? true : false}
                showWithSpaceBetween={true}
            />
            <CodeInputModal
                show={codeModalShow.show}
                modalText={codeModalShow.modalText}
                modalTitle={codeModalShow.modalTitle}
                onHide={() => handleCloseCodeInputModal()}
                onSubmit={handleSubmitCodeInputModal}
            />
            <CancelConfirmModal
                show={cancelConfirmModalShow.show}
                onHide={() => setCancelConfirmModalShow({ show: false })}
                onSubmit={() => handleCancelClickAfterConfirm()}
            />
            <BookSuccessScreen show={bookingSuccess.show} handleNext={bookingSuccess.handleNext} />
        </>
    );
};

export default BookingAppointment;
