import moment from 'moment';
import { useContext, useEffect } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import PersonalDataSummaray from '../../container/PersonalDataSummary/PersonalDataSummary';
import AppPropsContext from '../../context/appPropsContext';
import TerminierungContext, { ITimeslot } from '../../context/terminierungContext';
import { useAppointmentSelection } from '../../hooks/useAppointmentSelection';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import { useLanguage } from '../../hooks/useLanguage';
import useScreenResolution from '../../hooks/useScreenResolution';
import {
    APPOINTMENTTYPE_CAREUNIT,
    APPOINTMENTTYPE_DEPARTMENT,
    APPOINTMENTTYPE_PRACTITIONER,
    APPOINTMENTTYPE_ROOM,
    IAppointment,
    IParticipant,
    getLocationCareUnitLongNameFromResource,
    getLocationCareUnitNameFromResource,
    getLocationDepartmentLongNameFromResource,
    getLocationDepartmentNameFromResource,
    getLocationRoomLongNameFromResource,
    getLocationRoomNameFromResource,
    getPractitionerNameFromResource,
} from '../../utils/appointmentUtils';
import { isBootstrap_lg } from '../../utils/screenResolution';
import AppointmentCard from '../Cards/AppointmentCard';
import { CenterDiv, LeftDiv } from '../Div/StyledDiv';
import RemarkInputField from '../Inputs/RemarkInputField';
import { StyledParagraphWithoutMargin } from '../StyledParagraph/StyledParagraph';
import EarlierAppointmentPossible from './EarlierAppointment';

interface IBooking {
    reasonGroupText?: string;
    showEarlierAppointmentPossible?: boolean;
}

const Booking = (props: IBooking) => {
    const { tmstate } = useContext(TerminierungContext);
    const { apstate } = useContext(AppPropsContext);
    const { getSelectedResource, getSelectedTimeslot } = useAppointmentSelection();
    const screenSize = useScreenResolution();

    let multiAppointment = false;

    const selectedResource = getSelectedResource(1);
    const selectedTimeslot = getSelectedTimeslot(1);

    const practitioner = getPractitionerNameFromResource(selectedResource);
    const location = getLocationDepartmentNameFromResource(selectedResource);
    const locationDetail = getLocationCareUnitNameFromResource(selectedResource);
    const locationRoom = getLocationRoomNameFromResource(selectedResource);
    const { sendGoogleAnalyticsPageView } = useGoogleAnalytics();

    const { t } = useLanguage();

    useEffect(() => {
        sendGoogleAnalyticsPageView({ page: '/Booking', title: 'Termin buchen' });
    }, []);

    const participantData: IParticipant[] = [
        {
            actor: {
                display: tmstate.selectedDepartment?.name
                    ? tmstate.selectedDepartment.name
                    : getLocationDepartmentNameFromResource(selectedResource),
                reference: APPOINTMENTTYPE_DEPARTMENT + '/',
            },
            status: 'accepted',
        },
        {
            actor: {
                display: tmstate.selectedReason?.reasonName
                    ? tmstate.selectedReason.reasonName
                    : getLocationCareUnitNameFromResource(selectedResource),
                reference: APPOINTMENTTYPE_CAREUNIT + '/',
            },
            status: 'accepted',
        },
        {
            actor: {
                display: getLocationRoomNameFromResource(selectedResource),
                reference: APPOINTMENTTYPE_ROOM + '/',
            },
            status: 'accepted',
        },
        {
            actor: {
                display: tmstate.selectedPhysician.name
                    ? tmstate.selectedPhysician.name
                    : getPractitionerNameFromResource(selectedResource),
                reference: APPOINTMENTTYPE_PRACTITIONER + '/',
            },
            status: 'accepted',
        },
    ];

    const appointmentCardData: IAppointment = {
        id: '',
        start: selectedTimeslot?.start,
        end: selectedTimeslot?.end,
        status: 'geplant',
        resource: 'Appointment',
        participant: participantData,
        comment: '',
        type: 'upcoming',
        isCancelable: false,
        isInError: false,
        supressDuration: selectedTimeslot?.supressDuration,
    };

    const selectedResource_2 = getSelectedResource(2);
    // const selectedTimeslot_2 = getSelectedTimeslot(2);

    let practitioner_2 = '';
    let location_2 = '';
    let locationDetail_2 = '';
    let locationRoom_2 = '';
    // let participantData_2: IParticipant[] = [];
    // let appointmentCardData_2 = {} as IAppointment;

    if (selectedResource_2) {
        multiAppointment = true;

        practitioner_2 = getPractitionerNameFromResource(selectedResource_2);
        location_2 = getLocationDepartmentLongNameFromResource(selectedResource_2);
        locationDetail_2 = getLocationCareUnitLongNameFromResource(selectedResource_2);
        locationRoom_2 = getLocationRoomLongNameFromResource(selectedResource_2);

        // participantData_2 = [
        //     {
        //         actor: {
        //             display: getLocationDepartmentNameFromResource(tmstate.selectedResource_2),
        //             reference: 'Location/',
        //         },
        //         status: 'accepted',
        //     },
        //     {
        //         actor: {
        //             display: getLocationCareUnitNameFromResource(tmstate.selectedResource_2),
        //             reference: 'Location/',
        //         },
        //         status: 'accepted',
        //     },
        //     {
        //         actor: {
        //             display: getLocationRoomNameFromResource(tmstate.selectedResource_2),
        //             reference: 'Location/',
        //         },
        //         status: 'accepted',
        //     },
        //     {
        //         actor: {
        //             display: getParticipantNameFromResource(tmstate.selectedResource_2),
        //             reference: 'Practitioner/',
        //         },
        //         status: 'accepted',
        //     },
        // ];

        // appointmentCardData_2 = {
        //     id: '',
        //     start: tmstate.selectedTimeslot_2.start,
        //     end: tmstate.selectedTimeslot_2.end,
        //     status: 'geplant',
        //     resource: 'Appointment',
        //     participant: participantData_2,
        //     comment: '',
        //     type: 'upcoming',
        //     isCancelable: false,
        //     isInError: false,
        // };
    }

    interface ITerminSummary {
        practitioner?: string;
        location?: string;
        locationDetail?: string;
        room?: string;
        selectedTimeslot?: ITimeslot;
        terminName?: string;
    }

    const getHDBooking = () => {
        if (tmstate.hdBooking) {
            return <CenterDiv dangerouslySetInnerHTML={{ __html: tmstate.hdBooking }} />;
        } else {
            return (
                <>
                    <br />
                    <p>{t('ANMELD', 'Prüfen Sie Ihre Angaben.')}</p>
                </>
            );
        }
    };

    const TerminSummary = (props: ITerminSummary) => {
        const showDepartment = apstate.AppointmentSummary.fach;
        const showCareUnit = apstate.AppointmentSummary.pflege;
        const showRoom = apstate.AppointmentSummary.raum;
        const showPractitioner = apstate.AppointmentSummary.behandler;

        return (
            <>
                <StyledParagraphWithoutMargin data-name="booking-terminName">
                    <strong>
                        <u>{props.terminName}</u>
                    </strong>
                </StyledParagraphWithoutMargin>
                {showDepartment && (
                    <StyledParagraphWithoutMargin data-name="booking-location">
                        <strong>{props.location}</strong>
                    </StyledParagraphWithoutMargin>
                )}
                {showCareUnit && (
                    <StyledParagraphWithoutMargin data-name="booking-locationDetail">
                        <strong>{props.locationDetail}</strong>
                    </StyledParagraphWithoutMargin>
                )}
                {showPractitioner && (
                    <StyledParagraphWithoutMargin data-name="booking-practitioner">
                        <strong>{props.practitioner}</strong>
                    </StyledParagraphWithoutMargin>
                )}
                {showRoom && (
                    <StyledParagraphWithoutMargin data-name="booking-room">
                        <strong>{props.room}</strong>
                    </StyledParagraphWithoutMargin>
                )}
                <br />
                <StyledParagraphWithoutMargin>
                    {moment(props.selectedTimeslot?.start).format('dddd, DD.MM.YYYY')}
                </StyledParagraphWithoutMargin>
                <StyledParagraphWithoutMargin>
                    {moment(props.selectedTimeslot?.start).format('LT')} -{' '}
                    {moment(props.selectedTimeslot?.end).format('LT')} Uhr
                </StyledParagraphWithoutMargin>
            </>
        );
    };

    if (tmstate.withLogin) {
        const terminProps: ITerminSummary = {
            practitioner: practitioner,
            room: locationRoom,
            location: location,
            locationDetail: locationDetail,
            selectedTimeslot: getSelectedTimeslot(1),
            terminName: 'Termin 1',
        };

        let terminProps_2: ITerminSummary = {};
        if (multiAppointment) {
            terminProps_2 = {
                practitioner: practitioner_2,
                room: locationRoom_2,
                location: location_2,
                locationDetail: locationDetail_2,
                selectedTimeslot: getSelectedTimeslot(2),
                terminName: 'Termin 2',
            };
        }

        return (
            <LeftDiv>
                <h3>{t('ANMELD', 'Termin buchen')}</h3>
                <StyledParagraphWithoutMargin>Bitte prüfen Sie ihre Angaben:</StyledParagraphWithoutMargin>
                <br />
                {/* <StyledParagraphWithoutMargin>Ihr Termin</StyledParagraphWithoutMargin> */}
                <h4>{tmstate.selectedCategory.name}</h4>
                <Container>
                    <Row>
                        <Col>
                            <TerminSummary {...terminProps} />
                        </Col>
                        {multiAppointment && (
                            <Col>
                                <TerminSummary {...terminProps_2} />
                            </Col>
                        )}
                    </Row>
                </Container>
                {props.showEarlierAppointmentPossible && <EarlierAppointmentPossible />}
                <RemarkInputField groupText={props.reasonGroupText} />
            </LeftDiv>
        );
    } else {
        const mb = isBootstrap_lg(screenSize.width) ? '28px' : '0px';
        return (
            <>
                <div>
                    <Row sm={1} xs={1} lg={2}>
                        <Col>
                            <Row style={{ marginBottom: mb }}>
                                <Col>
                                    <LeftDiv>
                                        <h3>{t('ANMELD', 'Termin buchen')}</h3>
                                        {getHDBooking()}
                                    </LeftDiv>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <LeftDiv>
                                        <AppointmentCard
                                            {...appointmentCardData}
                                            showChatIcon={false}
                                            cardWidth={'100%'}
                                        />
                                    </LeftDiv>
                                </Col>
                            </Row>
                            {props.showEarlierAppointmentPossible && (
                                <Row>
                                    <Col>
                                        <EarlierAppointmentPossible />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        <Col style={{ marginTop: 'auto' }}>
                            <LeftDiv>
                                <PersonalDataSummaray />
                            </LeftDiv>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <LeftDiv>
                                <RemarkInputField groupText={props.reasonGroupText} />
                            </LeftDiv>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

    return <div></div>;
};

export default Booking;
