import { useContext } from 'react';
import { Form } from 'react-bootstrap';
import TerminierungContext from '../../context/terminierungContext';
import { StyledFormLabelForInput } from '../StyledForm/SyledForm';

interface IRemarkInputField {
    groupText?: string;
}

const RemarkInputField = (props: IRemarkInputField) => {
    const { tmstate, tmdispatch } = useContext(TerminierungContext);

    const setRemark = (value: string) => {
        tmdispatch({
            type: 'SETREMARK',
            remark: value,
        });
    };

    return (
        // <InputGroup className="mb-3 mt-3">
        //     <InputGroup.Prepend>
        //         <InputGroup.Text id="inputGroup-sizing-default">
        //             {props.groupText ? props.groupText : 'Grund für Termin'}
        //         </InputGroup.Text>
        //     </InputGroup.Prepend>
        //     <FormControl
        //         aria-label="Default"
        //         aria-describedby="inputGroup-sizing-default"
        //         value={tmstate.remark}
        //         onChange={(e) => setRemark(e.target.value)}
        //     />
        // </InputGroup>
        <Form.Group controlId="remarkInput" className="mb-3 mt-3">
            <StyledFormLabelForInput>
                {/* {t('LOGIN', 'Benutzername')} */}
                {props.groupText ? props.groupText : 'Grund für Termin'}
            </StyledFormLabelForInput>
            <Form.Control as="textarea" rows={3} value={tmstate.remark} onChange={(e) => setRemark(e.target.value)} />
        </Form.Group>
    );
};

export default RemarkInputField;
