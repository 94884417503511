import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import DragNDropArea from '../../components/Div/DragNDropArea';
import { CenterDiv } from '../../components/Div/StyledDiv';
import TelephoneModal from '../../components/Modals/TelephoneModal/TelephoneModal';
import { ITelephoneCallIcon, stopRingTone } from '../../components/Telephone/Telephone';
import LoginContext from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import MyThemeContext from '../../context/themeContext';
import VideochatContext from '../../context/videochatContext';
import useScreenResolution from '../../hooks/useScreenResolution';
import { initWebSocket } from '../../services/VideochatService';
import { isMobile } from '../../utils/screenResolution';
import Navbar from '../Navbar/Navbar';
import NavbarBottom from '../Navbar/NavbarBottom';
import Sidebar from '../Sidebar/Sidebar';

const Mainscreen = ({ children }: React.PropsWithChildren<unknown>) => {
    const navigate = useNavigate();
    const location = useLocation();
    const themeContext = useContext(ThemeContext);
    const { state } = useContext(LoginContext);
    const { themestate } = useContext(MyThemeContext);
    const { vsstate, vsdispatch } = useContext(VideochatContext);
    const { tmstate } = useContext(TerminierungContext);
    const [showTelephoneModal, setShowTelephoneModal] = useState(false);

    const screenSize = useScreenResolution();

    // window.localStorage.setItem(localStorageKey.lastInteraction, moment().format('DD.MM.YYYY HH:mm:ss'));

    const callProps: ITelephoneCallIcon = {
        state: state,
        vsstate: vsstate,
        vsdispatch: vsdispatch,
        tmstate: tmstate,
    };

    useEffect(() => {
        if (state.sessionId !== '') {
            if (!window.app.wsconnect) {
                initWebSocket(state.iceServers, state.sessionId, vsstate, vsdispatch, tmstate, navigate, location);
                // console.log('before WS');
                // console.log('ws: ', window.app.wsconnect);
            }
        }
    }, [state.sessionId, showTelephoneModal, window.app.wsconnect]);

    useEffect(() => {
        if (vsstate.incomingCall) {
            if (!showTelephoneModal) {
                setShowTelephoneModal(true);
            }
        }
    }, [vsstate.incomingCall]);

    const onTelephoneCallOff = () => {
        setShowTelephoneModal(false);
    };

    const onTelephoneCallAccept = () => {
        setShowTelephoneModal(false);
    };

    const onTelephoneHide = () => {
        stopRingTone();
        setShowTelephoneModal(false);
    };

    return (
        <div>
            <header>
                <Navbar />
            </header>
            <Container fluid>
                <Row>
                    {!isMobile(screenSize.width) && (
                        <Col
                            md={3}
                            xl={2}
                            xs={12}
                            className="vh-100 d-flex flex-column"
                            style={{
                                backgroundImage: themeContext.sidebar.backgroundImage,
                                boxShadow: '2px 0px 4px rgb(0 0 0 / 50%)',
                            }}
                        >
                            <Sidebar paddingTop="80px" />
                        </Col>
                    )}

                    <DragNDropArea>
                        <CenterDiv style={{ overflow: 'auto', height: '100vh' }}>
                            {children}
                            <TelephoneModal
                                show={showTelephoneModal}
                                callProps={callProps}
                                onCallAccept={() => onTelephoneCallAccept()}
                                onCallOff={() => onTelephoneCallOff()}
                                onCallHide={() => onTelephoneHide()}
                            />
                        </CenterDiv>
                    </DragNDropArea>
                </Row>
            </Container>
            {isMobile(screenSize.width) && <NavbarBottom />}
        </div>
    );
};

export default Mainscreen;
